<template>
  <a href="javascript:void(0)" @click="download">
    <CIcon v-c-tooltip="'Download'" name="cil-cloud-download" placement="top">
    </CIcon>
  </a>
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    /**
     * Opens new window and resquests to download from endpoint
     */
    download() {
      window.open(this.file.download, '_blank')
    }
  }
}
</script>
