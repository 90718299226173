<template>
  <div>
    <a v-if="mimeType.split('/')[0] == 'image'" :href="url" target="_blank">
      <CIcon
        v-c-tooltip="'Visualizar'"
        name="cil-magnifying-glass"
        placement="top"
      >
      </CIcon>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * URL to redirect the user to edit page
     */
    url: {
      type: String,
      default: ''
    },
    mimeType: {
      type: String,
      default: ''
    }
  }
}
</script>
