<template>
  <div>
    <CModal
      title="Recortar Imagem"
      color="info"
      size="lg"
      :show.sync="editFile.show"
      :close-on-backdrop="false"
    >
      <template #header>
        <h5 class="modal-title">Recortar Imagem</h5>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click.prevent="onEditFileClose"
        >
          ×
        </button>
      </template>
      <div class="edit-image">
        <img id="editImage" ref="editImage" :src="editFile.blob" />
      </div>
      <div class="mt-2">
        <div class="btn-group mr-3" role="group">
          <button
            type="button"
            class="btn btn-primary"
            @click="editFile.cropper.zoom('0.1')"
          >
            <i class="fa fa-search-plus" aria-hidden="true"></i>
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="editFile.cropper.zoom('-0.1')"
          >
            <i class="fa fa-search-minus" aria-hidden="true"></i>
          </button>
        </div>
        <div class="btn-group" role="group">
          <button
            type="button"
            class="btn btn-primary"
            @click="editFile.cropper.move('-10', '0')"
          >
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="editFile.cropper.move('10', '0')"
          >
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="editFile.cropper.move('0', '-10')"
          >
            <i class="fa fa-arrow-up" aria-hidden="true"></i>
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="editFile.cropper.move('0', '10')"
          >
            <i class="fa fa-arrow-down" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <template #footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click.prevent="onEditFileClose"
        >
          Fechar
        </button>
        <button type="button" class="btn btn-primary" @click="onEditorFile">
          Recortar e salvar
        </button>
      </template>
    </CModal>
    <a href="javascript:void(0)" @click="onEditFileShow(file)">
      <CIcon v-c-tooltip="'Recortar imagem'" name="cil-crop" placement="top" />
    </a>
  </div>
</template>

<script>
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.min.css'

export default {
  props: {
    /**
     * Image to be cropped
     */
    file: {
      type: Object,
      default: () => {}
    },
    /**
     * Instance of the uploader component
     */
    uploaderInstance: {
      type: Object,
      default: undefined
    },
    /**
     * The minimum width of the crop box.
     */
    minCroppedWidth: {
      type: Number,
      default: 0
    },

    /**
     * The minimum Height of the crop box.
     */
    minCroppedHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      editFile: {
        show: false,
        name: ''
      }
    }
  },
  watch: {
    'editFile.show'(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.uploaderInstance.update(this.editFile.id, {
          error: this.editFile.error || ''
        })
      }
      if (newValue) {
        this.$nextTick(() => {
          if (!this.$refs.editImage) {
            return
          }

          const cropper = new Cropper(this.$refs.editImage, {
            autoCrop: true,
            zoomOnWheel: false,
            cropBoxResizable: false,
            minCropBoxWidth: this.minCroppedWidth,
            minCropBoxHeight: this.minCroppedHeight,
            aspectRatio: this.minCroppedWidth / this.minCroppedHeight
          })

          this.editFile = {
            ...this.editFile,
            cropper
          }
        })
      }
    }
  },
  methods: {
    /**
     * Update file to edit
     */
    onEditFileShow(file) {
      this.editFile = { ...file, show: true }
      this.uploaderInstance.update(file)
    },
    /**
     * Destroy cropper instance and closes modal
     */
    onEditFileClose() {
      if (this.editFile.cropper) {
        this.editFile.cropper.destroy()
      }

      this.editFile = {
        show: false
      }
    },
    /**
     * Creates new cropped image
     * @see https://github.com/lian-yue/vue-upload-component/blob/master/docs/views/examples/Full.vue
     * @public
     */
    onEditorFile() {
      if (!this.uploaderInstance.features.html5) {
        this.alert('Your browser does not support')
        this.editFile.show = false
        return
      }
      const data = {
        name: this.editFile.name
      }
      if (this.editFile.cropper) {
        const binStr = atob(
          this.editFile.cropper
            .getCroppedCanvas()
            .toDataURL(this.editFile.type)
            .split(',')[1]
        )
        const arr = new Uint8Array(binStr.length)
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i)
        }
        data.file = new File([arr], data.name, { type: this.editFile.type })
        data.size = data.file.size
      }
      this.uploaderInstance.update(this.editFile.id, data)
      this.editFile.error = ''
      this.editFile.show = false
      if (this.editFile.cropper) {
        this.editFile.cropper.destroy()
      }
    }
  }
}
</script>

<style lang="scss">
/* Ensure the size of the image fit the container perfectly */
img {
  display: block !important;
  /* This rule is very important, please don't ignore this */
  max-width: 100%;
}
</style>
