import axios from './axios.service'
// eslint-disable-next-line no-unused-vars
import authHeader from './auth-header'

const method = 'media'

class MediaService {
  async updateMediaInfos(id, title, description, link) {
    try {
      const response = await axios({
        method: 'put',
        url: `/${method}/updateinfos/${id}`,
        data: {
          title,
          description,
          link
        }
      })
      return response
    } catch (error) {
      return error
    }
  }
}

export default new MediaService()
