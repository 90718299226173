<template>
  <div>
    <a href="javascript:void(0)" @click="toggleModal">
      <CIcon v-c-tooltip="'Editar'" name="cil-pencil" placement="top" />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * file to be updated
     */
    file: {
      type: Object,
      default: () => {}
    },
    displayModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modal: {
        show: false
      },
      editFile: {
        name: '',
        description: ''
      }
    }
  },
  methods: {
    /**
     * Show edit modal
     * @public
     */
    toggleModal() {
      const payLoad = {
        displayModal: true,
        file: this.file
      }

      this.$emit('onEdit', payLoad)
    }
  }
}
</script>
