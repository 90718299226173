<template>
  <div>
    <CModal title="Editar" color="info" :show="show" @update:show="toggleModal">
      <template #body-wrapper>
        <div v-if="editFile.blob || editFile.url" class="bg-container">
          <div
            class="bg-preview"
            :style="`background-image: url(${editFile.blob || editFile.url})`"
          />
          <div
            class="frame-preview-image"
            :style="`background-image: url(${editFile.blob || editFile.url})`"
          />
        </div>

        <div class="modal-form mt-2">
          <div class="form-group">
            <label for="title">Título da imagem</label>
            <input
              v-model="editFile.title"
              class="form-control"
              type="text"
              placeholder="Título"
              @keypress.enter="(event) => event.preventDefault()"
            />
          </div>
          <div class="form-group mt-3">
            <label for="description">Legenda</label>
            <textarea
              v-model="editFile.description"
              class="form-control"
              placeholder="Legenda"
              name="description"
              cols="30"
              rows="5"
            ></textarea>
          </div>
        </div>
      </template>
      <template #footer>
        <button type="button" class="btn btn-secondary" @click="toggleModal">
          Cancelar
        </button>
        <button type="button" class="btn btn-success" @click="updateFile">
          Salvar
        </button>
      </template>
    </CModal>
  </div>
</template>

<script>
import MediaService from '@/services/media.service'

export default {
  props: {
    /**
     * file to be updated
     */
    file: {
      type: Object,
      default: () => {}
    },

    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      editFile: {
        title: '',
        description: '',
        link: ''
      }
    }
  },

  updated() {
    this.editFile = {
      ...this.file,
      title: this.file.custom && this.file.custom.title,
      description: this.file.custom && this.file.custom.description,
      link: this.file.custom && this.file.custom.link
    }
  },

  methods: {
    /**
     * Show edit modal
     * @public
     */
    toggleModal() {
      this.$emit('onCloseModal', false)
    },
    /**
     * Update file on endpoint
     */
    async updateFile() {
      await MediaService.updateMediaInfos(
        this.editFile.id,
        this.editFile.title,
        this.editFile.description,
        this.editFile.link
      )
      this.toggleModal()
      this.$notify({
        group: 'notifications',
        type: 'success',
        text: 'Informações de media atualizadas com sucesso'
      })
      this.$emit('onEditFileSaved', this.editFile)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-form {
  padding: 20px;
}

.bg-container {
  height: 120px;
  position: relative;

  .bg-preview {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    // z-index: -1;
    -webkit-filter: blur(13px);
    filter: blur(13px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .frame-preview-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 120px;
    // margin-top: 10px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    border: 2px solid transparent;
    background-color: hsla(0, 25%, 97%, 0.44);
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  }
}
</style>
